import React, {
  useMemo,
  useState,
} from 'react'
import SVG from 'react-inlinesvg'

import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  Box,
  Card,
  Grid,
  Icon,
  Avatar,
  Button,
  Drawer,
  CardMedia,
  Typography,
  CardContent,
} from '@material-ui/core'
import {
  CallOutlined as CallOutlinedIcon,
} from '@material-ui/icons'

import useUtils from '../../hooks/useUtils'
import useSecurity from '../../../security/useSecurity'

import { GlobalMessages } from '../../messages'
import useUserClient from '../../../clients/UserClient/useUserClient'

import icoWhats from '../../../assets/img/icons/ico-whats.svg'
import imgCallCenter from '../../../assets/img/float-buttons/img-callcenter.svg'
import bgModalExecutive from '../../../assets/img/backgrounds/bg-modal-executive.svg'

import { useLoader } from '../Loader'

const useStyles = makeStyles((theme) => ({
  container: {
    right: 35,
    bottom: 160,
    zIndex: 99,
    position: 'fixed',
    [theme.breakpoints.up('lg')]: {
      bottom: 165,
    },
  },
  paper: {
    width: 400,
    marginLeft: 35,
  },
  btn: {
    top: 70,
    minWidth: 68,
    minHeight: 68,
    maxWidth: 68,
    maxHeight: 68,
    marginLeft: 130,
    display: 'flex',
    textAlign: 'center',
    borderRadius: ' 50%',
    justifyContent: 'center',
    backgroundImage: `linear-gradient(90deg, #19B8DA, ${theme.palette.primary.main} 80%)`,
    '& .MuiAvatar-root': {
      minWidth: 56,
      minHeight: 56,
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 60,
      minHeight: 60,
    },
  },
  btnMsgNewExecutive: {
    top: 70,
    minWidth: 68,
    minHeight: 68,
    maxWidth: 68,
    maxHeight: 68,
    display: 'flex',
    textAlign: 'center',
    borderRadius: ' 50%',
    justifyContent: 'center',
    backgroundImage: `linear-gradient(90deg, #19B8DA, ${theme.palette.primary.main} 80%)`,
    '& .MuiAvatar-root': {
      minWidth: 56,
      minHeight: 56,
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 60,
      minHeight: 60,
    },
  },
  photoStandard: {
    minWidth: 56,
    minHeight: 56,
  },
  root: {
    color: theme.palette.text.quaternary,
    padding: theme.spacing(4),
    '& .MuiTypography-h5': {
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #F6F6F6',
    },
  },
  media: {
    height: 70,
    borderRadius: 0,
    animation: 'fadein 0.5s',
    backgroundImage: `url(${bgModalExecutive})`,
  },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  avatarModal: {
    width: 70,
    height: 70,
    top: theme.spacing(3),
    left: theme.spacing(4),
    marginTop: -10,
  },
  buttonContact: {
    padding: 0,
    width: '100%',
    color: 'inherit',
    '& .MuiButton-label': {
      justifyContent: 'left',
    },
    '& path': {
      fill: theme.palette.info.dark,
    },
  },
  iconWhatsApp: {
    width: '24',
    height: '24',
    color: '#55CA64',
    marginRight: 8,
    // '& path': {
    //   fill: '#55CA64',
    // },
  },
  infoTooltip: {
    fontSize: 20,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  popoverWhats: {
    pointerEvents: 'none',
  },
  paperWhats: {
    padding: theme.spacing(1),
  },
  customTooltip: {
    fontSize: '22px',
    backgroundColor: '#F2F2F2',
    padding: '10px',
  },
  msgBox: {
    width: 200,
    height: 50,
    zIndex: 9,
    backgroundColor: '#fff',
    border: 1,
    marginTop: 45,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    borderColor: '#115192',
    boxShadow: '0.66px 2.66px 5.33px rgba(0, 0, 0, 0.41)',
  },
  msgNewExecutive: {
    paddingTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: 12,
    color: '#115192',
    fontWeight: 700,
    // color: '#126ba3',
    // fontWeight: 500,
  },
}))

const ExecutiveBtn = (props) => {
  const classes = useStyles()
  const userClient = useUserClient()
  const { hideLoader } = useLoader()
  const { getOnlyNumber, formatPhoneOrCellphone } = useUtils()
  const {
    getExecutive, getSuperIntendent, getAssistants,
  } = useSecurity()

  const [anchorEl, setAnchorEl] = useState(null)

  const executive = useMemo(() => getExecutive(), [getExecutive])
  const assistants = useMemo(() => getAssistants(), [getAssistants])
  const superIntendent = useMemo(() => getSuperIntendent(), [getSuperIntendent])

  const newExecutiveNotification = localStorage.getItem('newExecutiveNotification')
  const [meetYourNewExecutive, setMeetYourNewExecutive] = useState(newExecutiveNotification)

  const superIntendentData = {
    ...superIntendent,
    title: 'Superintendente',
  }

  const assistantsData = assistants.length > 0 ? assistants?.map((item) => ({
    ...item,
    title: 'Assistente',
  })) : []

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const arrayItemsSuperIntendent = [
    executive,
    ...assistantsData,
    superIntendentData,
  ]

  const arrayItems = [
    executive,
    ...assistantsData,
  ]

  const listDataFilter = superIntendent?.name === undefined ? arrayItems : arrayItemsSuperIntendent

  const open = Boolean(anchorEl)

  const getNumber = (valuePhone) => `55${getOnlyNumber(valuePhone)}`

  const meetYourNewExecutiveValidate = (meetYourNewExecutive === 'true' || meetYourNewExecutive === true)

  const sendMeetYourNewExecutive = (event) => {
    handlePopoverOpen(event)
    if (meetYourNewExecutiveValidate) {
      hideLoader()
      userClient().sendUserNewExecutiveVisualized()
      setMeetYourNewExecutive(false)
      localStorage.setItem('newExecutiveNotification', false)
    }
  }

  return (
    <div className={classes.container} {...props}>
      <Box
        style={{
          width: 0,
          height: 0,
          display: 'flex',
          position: 'absolute',
          right: 200,
          bottom: 100,
          zIndex: 9,
        }}
      >
        {meetYourNewExecutiveValidate && (
          <Box
            className={classes.msgBox}
            onClick={sendMeetYourNewExecutive}
          >
            <Typography
              gutterBottom
              component="div"
              variant="body1"
              color="primary"
              className={classes.msgNewExecutive}
            >
              Conheça seu novo executivo
            </Typography>
          </Box>
        )}
        <Button
          className={meetYourNewExecutiveValidate ? classes.btnMsgNewExecutive : classes.btn}
          aria-owns={open ? 'info-executive' : undefined}
          onClick={sendMeetYourNewExecutive}
        >
          <Avatar
            alt="avatar-executive"
            src={executive.photoStandard}
          />
        </Button>
      </Box>

      <Drawer
        anchor="right"
        open={open}
        anchorel={anchorEl}
        onClose={handlePopoverClose}
        anchororigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformorigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        disableRestoreFocus
        disableScrollLock
      >
        <Card
          style={{
            borderRadius: 0,
            overflow: 'auto',
            '& .MuiFormGroupRoot': {
              width: '100%',
            },
          }}
        >
          {listDataFilter?.map((item, index) => (
            <Box key={index}>
              <CardMedia
                className={classes.media}
                alt="Fundo degradê com tons de azul"
              >
                <Avatar
                  alt="avatar-executive"
                  className={classes.avatarModal}
                  src={item?.photoFull ?? imgCallCenter}
                />
                <Icon
                  title="Fechar"
                  className={classes.close}
                  onClick={handlePopoverClose}
                >
                  close
                </Icon>
              </CardMedia>
              <CardContent className={classes.root}>
                <Typography gutterBottom variant="h5" component="h2">
                  {item?.name}
                </Typography>
                <Grid item container direction="column" spacing={2}>
                  <Grid item>
                    <Typography color="primary">
                      <b>{item?.title ?? 'Executivo Responsável'}</b>
                    </Typography>
                  </Grid>
                  {(item?.cellPhone && item?.cellPhone !== '' && (
                  <Grid item>
                    <Button
                      className={classes.buttonContact}
                      href={`https://api.whatsapp.com/send?phone=${getNumber(item?.cellPhone ?? '')}&text=Olá!`}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="WhatsApp"
                    >
                      <Box mr={1}>
                        <SVG src={icoWhats} className={classes.iconWhatsApp} />
                      </Box>
                      <Typography variant="body2">
                        {item?.cellPhone !== null
                          ? formatPhoneOrCellphone(item?.cellPhone) : GlobalMessages.NAO_DEFINIDO}
                      </Typography>
                    </Button>
                  </Grid>
                  )
                  )}
                  <Grid item>
                    <Button
                      className={classes.buttonContact}
                      title="Central de Atendimento"
                    >
                      <Box mr={1}>
                        <CallOutlinedIcon />
                      </Box>
                      <Typography variant="body2" style={{ marginLeft: 9 }}>
                        0800 602 7688
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
              <Box height={2} width="100%" bgcolor="#F2F2F2" />
            </Box>
          ))}
        </Card>
      </Drawer>
    </div>
  )
}

export default ExecutiveBtn
